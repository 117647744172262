import {Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Theme, withStyles} from '@material-ui/core';
import {AccountCircle, BorderAll, Dashboard, Grain, Help, List as ListIcon, Public, Smartphone, StarBorder, ExpandLess, ExpandMore, ExitToApp} from '@material-ui/icons';
import i18n from 'i18next';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {IState as Session} from '../../global/session';

const styles = (theme: Theme) => ({
	nested: {
		paddingLeft: theme.spacing.unit * 4,
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		maxWidth: 360,
		width: '100%',
	},
});

interface IProps {
	classes: any;
	session: Session;
	t: i18n.TFunction;
}

interface IState {
	open: boolean;
}

const mapStateToProps = (state: any) => {
	return {
		session: state.session,
	};
};

class Navigation extends React.Component<IProps, IState> {
	public state = {
		open: false,
	};

	public render() {
		const {classes, session, t} = this.props;
		const {open} = this.state;
		const {email} = session.contents as any;
		const {pathname} = window.location;
		return (
			<div>
				<List>
					<div>
						<ListItem button={true} onClick={this.handleAccountClick} selected={pathname.startsWith('/account')}>
							<ListItemIcon>
								<AccountCircle />
							</ListItemIcon>
							<ListItemText primary={t('account', {email})} />
							{open ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={open} timeout='auto' unmountOnExit={true}>
							<List component={Link} to='/logout' disablePadding={true}>
								<ListItem button={true} className={classes.nested}>
								<ListItemIcon>
									<ExitToApp />
								</ListItemIcon>
								<ListItemText inset={true} primary={t('logout')} />
								</ListItem>
							</List>
						</Collapse>
					</div>
				</List>
				<Divider />
				<List>
					<div>
						<ListItem button={true} component={Link} to='/dashboard' selected={pathname.startsWith('/dashboard')}>
							<ListItemIcon>
								<Dashboard />
							</ListItemIcon>
							<ListItemText primary={t('dashboard')} />
						</ListItem>
						<ListItem button={true} component={Link} to='/mytests' selected={pathname.startsWith('/mytests')}>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
							<ListItemText primary={t('mytests')} />
						</ListItem>
						{/*
						<ListItem button={true} component={Link} to='/experiments' selected={pathname.startsWith('/experiments')}>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
							<ListItemText primary={t('experiments')} />
						</ListItem>
						*/}
					</div>
				</List>
				<Divider />
				<List>
					<div>
						<ListItem button={true} component={Link} to='/devices' selected={pathname.startsWith('/devices')}>
							<ListItemIcon>
								<Smartphone />
							</ListItemIcon>
							<ListItemText primary={t('devices')} />
						</ListItem>
						{/*
						<ListItem button={true} component={Link} to='/stations' selected={pathname.startsWith('/stations')}>
							<ListItemIcon>
								<BorderAll />
							</ListItemIcon>
							<ListItemText primary={t('stations')} />
						</ListItem>
						<ListItem button={true} component={Link} to='/subjects' selected={pathname.startsWith('/subjects')}>
							<ListItemIcon>
								<Grain />
							</ListItemIcon>
							<ListItemText primary={t('subjects')} />
						</ListItem>
						*/}
					</div>
				</List>
				{/*
				<Divider />
				<List>
					<div>
						<ListItem button={true} component={Link} to='/public' selected={pathname.startsWith('/public')}>
							<ListItemIcon>
								<Public />
							</ListItemIcon>
							<ListItemText primary={t('public')} />
						</ListItem>
					</div>
				</List>
				*/}
				<Divider />
				<List>
					<div>
						<ListItem button={true} component={Link} to='/help' selected={pathname.startsWith('/help')}>
							<ListItemIcon>
								<Help />
							</ListItemIcon>
							<ListItemText primary={t('help')} />
						</ListItem>
					</div>
				</List>
			</div>
		);
	}

	private handleAccountClick = () => {
		this.setState((state) => ({
			open: !state.open,
		}));
	}
}

export default connect(mapStateToProps)(withTranslation('navigation')(withStyles(styles)(Navigation)));

import JwtDecode from 'jwt-decode';
import {Store} from 'redux';

const types = {
	_SESSION_DESTROY_TOKEN: '_SESSION_DESTROY_TOKEN',
	_SESSION_MARK_EXPIRED: '_SESSION_MARK_EXPIRED',
	_SESSION_MARK_UPDATED: '_SESSION_MARK_UPDATED',
	_SESSION_SET_TOKEN: '_SESSION_SET_TOKEN',
};

type UpdateTokenCallback = (currentToken: string) => Promise<string>;

interface IJwtContents {
	iat: number;
	exp: number;
}

interface INewOptions {
	interval?: number;
	key?: string;
	updateRatio?: number;
	updateToken?: UpdateTokenCallback;
}

interface IOptions {
	createDefaultIat: boolean;
	interval: number;
	key: string;
	updateRatio: number;
	updateToken: UpdateTokenCallback;
}

interface IState {
	contents: IJwtContents;
	expired: number;
	token: string;
	updated: number;
}

interface IActionReturn {
	contents?: IJwtContents;
	expired?: number;
	options: IOptions;
	token?: string;
	type: string;
	updated?: number;
}

const destroyToken = (options: IOptions) => {
	return {
		options,
		type: types._SESSION_DESTROY_TOKEN,
	};
};

const markExpired = (options: IOptions, expired: number) => {
	return {
		expired,
		options,
		type: types._SESSION_MARK_EXPIRED,
	};
};

const markUpdated = (options: IOptions, updated: number) => {
	return {
		options,
		type: types._SESSION_MARK_UPDATED,
		updated,
	};
};

const setToken = (options: IOptions, contents: IJwtContents, token: string) => {
	return {
		contents,
		options,
		token,
		type: types._SESSION_SET_TOKEN,
	};
};

const actions = {
	destroyToken,
	markExpired,
	markUpdated,
	setToken,
};

const DefaultOptions: IOptions = {
	createDefaultIat: true,
	interval: 5000,
	key: '__jwt_session_token',
	updateRatio: 0.50,
	updateToken: (currentToken) => new Promise(() => true),
};

class Session {
	public static getUsage(contents: IJwtContents) {
		return (Number(new Date()) / 1000 - contents.iat) / (contents.exp - contents.iat);
	}

	private options: IOptions = DefaultOptions;
	private storage: ReduxStorage;

	constructor(store: Store, newOptions: INewOptions = {}) {
		this.options = {...this.options, ...newOptions};
		if (this.options.hasOwnProperty('updateRatio') && (this.options.updateRatio < 0 || this.options.updateRatio >= 1)) {
			const e = {error: '"updateRatio" must be a number between 0 and 1'};
			throw e;
		}
		if (this.options.hasOwnProperty('interval') && !(this.options.interval > 10)) {
			const e = {error: '"interval" must be a number greater than 10'};
			throw e;
		}
		if (this.options.hasOwnProperty('updateToken') && typeof this.options.updateToken !== 'function') {
			const e = {error: '"updateToken" must be a function returning a promise'};
			throw e;
		}
		if (this.options.hasOwnProperty('key') && typeof this.options.key !== 'string') {
			const e = {error: '"key" must be a string'};
			throw e;
		}

		this.storage = new ReduxStorage(store, this.options);
		this.checkUsage();
		setInterval(this.checkUsage, this.options.interval);
	}

	public getState = () => {
		return this.storage.getState();
	}

	public getToken = () => {
		const {token} = this.getState();
		return token;
	}

	public getContents = () => {
		const {contents} = this.getState();
		return contents;
	}

	public getExpiredCount = () => {
		const {expired} = this.getState();
		return expired;
	}

	public getUpdatedCount = () => {
		const {updated} = this.getState();
		return updated;
	}

	public setToken = (token: string) => {
		let contents = {iat: 0, exp: 0};
		if (token) {
			contents = JwtDecode(token);
			if (this.options.createDefaultIat && !contents.iat) {
				contents.iat = Math.trunc(new Date().getTime() / 1000);
			}
		}
		return this.storage.setToken(contents, token);
	}

	public clearToken = () => {
		this.setToken('');
	}

	public getTokenUsage = () => {
		return Session.getUsage(this.getContents());
	}

	public checkUsage = () => {
		const contents = this.getContents();
		const usage = Session.getUsage(contents);
		if (usage > 1 && !this.getExpiredCount()) {
			this.storage.destroyToken();
		}
		if (this.options.updateRatio < usage && !this.getUpdatedCount()) {
			this.options.updateToken(this.getToken())
				.then(this.setToken);
			this.storage.markUpdated(1);
		}
	}
}

class ReduxStorage { // tslint:disable-line
	private store: any;
	private options: IOptions;

	constructor(store: any, options: IOptions) {
		this.store = store;
		this.options = options;
	}

	public getState = () => {
		const state = this.store.getState();
		return state[this.options.key] || {};
	}

	public destroyToken = () => {
		return this.store.dispatch(destroyToken(this.options));
	}

	public markUpdated = (updated: number) => {
		return this.store.dispatch(markUpdated(this.options, updated));
	}

	public markExpired = (expired: number) => {
		return this.store.dispatch(markExpired(this.options, expired));
	}

	public setToken = (contents: IJwtContents, token: string) => {
		return this.store.dispatch(setToken(this.options, contents, token));
	}
}

const defaultContents: IJwtContents = {
	exp: 0,
	iat: 0,
};

const defaultState: IState = {
	contents: defaultContents,
	expired: 0,
	token: '',
	updated: 0,
};

const reducer = (state = defaultState, action: IActionReturn): IState => {
	switch (action.type) {
	case types._SESSION_DESTROY_TOKEN:
		return {
			...state,
			contents: defaultContents,
			expired: 1,
			token: '',
			updated: 1,
		};
	case types._SESSION_MARK_EXPIRED:
		return {
			...state,
			expired: action.expired as number,
		};
	case types._SESSION_MARK_UPDATED:
		return {
			...state,
			updated: action.updated as number,
		};
	case types._SESSION_SET_TOKEN:
		return {
			...state,
			contents: action.contents as IJwtContents,
			expired: 0,
			token: action.token as string,
			updated: 0,
		};
	default:
		return state;
	}
};

export {
	Session,
	actions,
	reducer,
	types,
	IState,
};

import {getAPIUrl, getCallAPIUrlFunction, getHeaders, transformIndex} from '../global/globals';
import {Realtime, wrapReducer} from '../global/realtimeredux';
import {
	combine, createCall, createResource, IStateBucket,
} from '../global/reduxapi';

interface IMessage {
	message: string;
}

export interface IReturn {
	can_export: boolean;
	data_internal_uri: string;
	data_public_uri: string;
	deleted_at: string;
	deleted_reason: string;
	display_label: string;
	id: number;
	messages: IMessage[];
	meta_data: object;
	model_updates_endpoint: string;
	model_updates_topic: string;
	realtime_endpoint: string;
	realtime_topic: string;
	realtime_uri: string;
	reason: string;
	sibling_data_sets: object;
	status: string;
	test: number;
	thumbnail_url: string;
	url: string;
	uuid: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {
}

interface ISubscriptions {
	[key: number]: Realtime;
}

const resource = createResource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: 'patch',
	name: 'dataset',
	rootUrl: getAPIUrl('/dataSets'),
	stringifyBody: function stringifyBody(requestBody: ISend) {
		return JSON.stringify(requestBody);
	},
	transformIndex,
});

const realtimeSubscriptions: ISubscriptions = {};
const updateSubscriptions: ISubscriptions = {};

const subscribeRealtime = function f(dataset: IReturn) {
	if (!realtimeSubscriptions.hasOwnProperty(dataset.id)) {
		realtimeSubscriptions[dataset.id] =
			new Realtime('dataset', dataset.id.toString(), dataset.realtime_endpoint, dataset.realtime_topic);
	}
	return realtimeSubscriptions[dataset.id];
};

const subscribeUpdates = function f(dataset: IReturn) {
	if (!updateSubscriptions.hasOwnProperty(dataset.id)) {
		updateSubscriptions[dataset.id] =
			new Realtime('dataset', dataset.id.toString(), dataset.model_updates_endpoint, dataset.model_updates_topic, 'updates');
	}
	return updateSubscriptions[dataset.id];
};

const {actions, types, reducer: r} = combine(resource, {
	finalize_append: createCall<{}, {}, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: 'PUT',
		name: 'finalize_append',
		url: getCallAPIUrlFunction('/dataSets', 'finalize_append'),
	}),
	initialize_append: createCall<{}, {}, IStateBucket<IReturn>>({
		headers: getHeaders,
		method: 'PUT',
		name: 'initialize_append',
		url: getCallAPIUrlFunction('/dataSets', 'initialize_append'),
	}),
});
const reducer = wrapReducer('dataset', r);
export {actions, types, reducer, subscribeRealtime, subscribeUpdates};

import React from 'react';
import {connect} from 'react-redux';
import store from '../../store/store';
import {actions as userActions, IReturn as User} from '../../resources/user';

const mapStateToProps = (state: any, props: IProps) => {
	return {
		user: state.user[props.userId],
	};
};

interface IProps {
	user?: User;
	userId: number;
}

class UserName extends React.Component<IProps> {
	public componentDidMount() {
		const {userId} = this.props;
		store.dispatch(userActions.cacheread({store}, userId))
			.catch(console.error);
	}

	public render() {
		const {user} = this.props;
		if (!user || !user.id) {
			return null;
		}

		return user.email;
	}
}

export default connect(mapStateToProps)(UserName);

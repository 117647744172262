import {getAPIUrl, getCallAPIUrlFunction, getHeaders, transformIndex} from '../global/globals';
import {
	combine, createCall, createResource, idUrlBuilder, IMeta, IStateBucket,
} from '../global/reduxapi';

export interface IReturn {
	email: string;
	id: number;
	first_name: string;
	last_name: string;
}

export type ISend = Partial<IReturn>;

export interface IParameters {
	is_schema: number;
	schema_id: string;
}

const resource = createResource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: 'PATCH',
	name: 'user',
	rootUrl: getAPIUrl('/users'),
	stringifyBody: function stringifyBody(requestBody: ISend) {
		return JSON.stringify(requestBody);
	},
	transformIndex,
});

const {actions, types, reducer} = combine(
	resource,
	{
	},
);
export {actions, types, reducer};

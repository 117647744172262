import {actions as meActions} from '../resources/me';
import store from '../store/store';
import {reducer, Session} from './session';

export interface IReturn {
	result: {
		token: string;
		UserId: number;
	};
}

const session = new Session(store, {
	key: 'session',
	updateRatio: 0.20,
	updateToken: (currentToken) => {
		return store.dispatch(meActions.refresh(undefined, {token: currentToken}))
			.then(({result}: IReturn) => {
				return result.token;
			});
	},
});
(window as any).session = session;

export default session;
export {
	reducer,
};

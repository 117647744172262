import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {IState} from '../../global/session';

const mapStateToProps = (state: any) => {
	return {
		session: state.session,
	};
};

interface IProps {
	session: IState;
}

class Default extends Component<IProps> {
	public render() {
		const {session} = this.props;

		return (
			<div>
				{session.token === ''
					? <Redirect to={'/login'} />
					: <Redirect to={'/dashboard'} />
				}
			</div>
		);
	}
}

export default connect(mapStateToProps)(Default);

import i18n from 'i18next';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Image, StyleSheet, View} from 'react-native';
import {Text} from 'react-native-elements';
import {connect} from 'react-redux';
import {actions as deviceSchemaActions, IReturn as IDeviceSchema} from '../../resources/deviceschema';
import store from '../../store/store';

interface IProps {
	deviceSchema?: IDeviceSchema;
	deviceSchemaId: number;
	t: i18n.TFunction;
}

const mapStateToProps = (state: any, ownProps: IProps) => {
	return {
		deviceSchema: state.deviceschema[ownProps.deviceSchemaId],
	};
};

class DeviceSchemaInfo extends Component<IProps> {
	public componentDidMount() {
		const {deviceSchemaId} = this.props;
		store.dispatch(deviceSchemaActions.cacheread({store}, deviceSchemaId))
			.catch(console.error)
		;
	}

	public render() {
		const {deviceSchema} = this.props;
		if (!deviceSchema || !deviceSchema.id) {
			return null;
		}

		return deviceSchema.label;
	}
}

export default connect(mapStateToProps)(withTranslation('deviceSchemaInfo')(DeviceSchemaInfo));

import {getAPIUrl, getHeaders, transformIndex} from '../global/globals';
import {
	combine, createResource,
} from '../global/reduxapi';

export interface IReturn {
	created: string;
	id: string;
	icon: string;
	label: string;
	slug: string;
	updated: string;
	device_vendor: number;
	requires_network_provisioning: boolean;
}

export type ISend = Partial<IReturn>;

const resource = createResource<{}, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: 'patch',
	name: 'deviceschema',
	rootUrl: getAPIUrl('/deviceschema'),
	stringifyBody: function stringifyBody(requestBody: ISend) {
		return JSON.stringify(requestBody);
	},
	transformIndex,
});

const {actions, types, reducer} = combine(resource);
export {actions, types, reducer};

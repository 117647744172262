import {getAPIUrl, getCallAPIUrlFunction, getHeaders, transformIndex} from '../global/globals';
import {
	combine, createCall, createResource, idUrlBuilder, IMeta, IStateBucket,
} from '../global/reduxapi';

export interface IReturn {
	configuration: object;
	created: string;
	device_id: string;
	id: string;
	interface_vendor: number;
	label: string;
	last_communication?: string;
	location: string;
	logo: string;
	network: string;
	network_provisioned?: string;
	online: false;
	realtime_datasets?: any[];
	schema: number;
	updated: string;
	user: number;
}

export type ISend = Partial<IReturn>;

export interface IParameters {
	is_schema: number;
	schema_id: string;
}

const resource = createResource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: 'PATCH',
	name: 'device',
	rootUrl: getAPIUrl('/devices'),
	stringifyBody: function stringifyBody(requestBody: ISend) {
		return JSON.stringify(requestBody);
	},
	transformIndex,
});

const {actions, types, reducer} = combine(
	resource,
	{
		add_collaborator: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'POST',
			name: 'add_collaborator',
			url: getCallAPIUrlFunction('/devices', 'add_collaborator'),
		}),

		append: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'PUT',
			name: 'append',
			url: getCallAPIUrlFunction('/devices', 'append'),
		}),

		bulk_write: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'PUT',
			name: 'bulk_write',
			url: getCallAPIUrlFunction('/devices', 'bulk_write'),
		}),

		claim: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'POST',
			name: 'claim',
			url: getCallAPIUrlFunction('/devices', 'claim'),
		}),

		change_collaborator: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'PUT',
			name: 'change_collaborator',
			url: getCallAPIUrlFunction('/devices', 'change_collaborator'),
		}),

		finalize_append: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'PUT',
			name: 'finalize_append',
			url: getCallAPIUrlFunction('/devices', 'finalize_append'),
		}),

		firmware: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'GET',
			name: 'firmware',
			url: getCallAPIUrlFunction('/devices', 'firmware'),
		}),

		get_child_devices: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'GET',
			name: 'get_child_devices',
			url: getCallAPIUrlFunction('/devices', 'get_child_devices'),
		}),

		get_collaborators: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'GET',
			name: 'get_collaborators',
			url: getCallAPIUrlFunction('/devices', 'get_collaborators'),
		}),

		get_config: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'GET',
			name: 'get_config',
			url: getCallAPIUrlFunction('/devices', 'get_config'),
		}),

		get_or_register: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'POST',
			name: 'get_or_register',
			reducer: (state: IStateBucket<IReturn> = {}, action) => {
				if (action.type !== 'GET_OR_REGISTER_SUCCESS') {
					return state;
				}
				if (!action.result) {
					return state;
				}
				const result = action.result as unknown as IReturn;
				return resource.updateEntity(state, result.id, result);
			},
			url: getAPIUrl('/devices/get_or_register'),
		}),

		get_or_add_realtime_dataset: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'POST',
			name: 'get_or_add_realtime_dataset',
			url: getCallAPIUrlFunction('/devices', 'get_or_add_realtime_dataset'),
		}),

		map: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'PUT',
			name: 'map',
			url: getCallAPIUrlFunction('/devices', 'map'),
		}),

		preview: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'GET',
			name: 'preview',
			url: getCallAPIUrlFunction('/devices', 'preview'),
		}),

		qrcode: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'GET',
			name: 'qrcode',
			url: getCallAPIUrlFunction('/devices', 'qrcode'),
		}),

		realtime_datasets: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'GET',
			name: 'realtime_datasets',
			url: getCallAPIUrlFunction('/devices', 'realtime_datasets'),
		}),

		remove_collaborator: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'DELETE',
			name: 'remove_collaborator',
			url: getCallAPIUrlFunction('/devices', 'remove_collaborator'),
		}),

		rename: createCall<{}, {}, IStateBucket<IReturn>>({
			headers: getHeaders,
			method: 'POST',
			name: 'rename',
			url: getCallAPIUrlFunction('/devices', 'rename'),
		}),
	},
);
export {actions, types, reducer};

import {getAPIUrl, getCallAPIUrlFunction, getHeaders, transformIndex} from '../global/globals';
import {combine, createCall, createResource, idUrlBuilder, IMeta, IStateBucket} from '../global/reduxapi';

export interface IReturn {
	created: string;
	deleted_at?: string;
	deleted_reason: string;
	has_collaborators: boolean;
	id: number;
	is_open: boolean;
	label: string;
	live_test: boolean;
	my_permissions: string[];
	name: number;
	schema: number;
	subject_count: number;
	supports_multichamber: boolean;
	updated: string;
	user: number;
}

export type ISend = Partial<IReturn>;

export interface IParameters {
	is_schema: number;
	schema_id: string;
}

const resource = createResource<IParameters, ISend, IReturn>({
	headers: getHeaders,
	methodUpdate: 'PATCH',
	name: 'test',
	rootUrl: getAPIUrl('/tests'),
	stringifyBody: function stringifyBody(requestBody: ISend) {
		return JSON.stringify(requestBody);
	},
	transformIndex,
});

const {actions, types, reducer} = combine(
	resource,
	{
	},
);
export {actions, types, reducer};

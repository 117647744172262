import {Theme, withStyles} from '@material-ui/core/styles';
import i18n from 'i18next';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Main from '../components/Main';

const styles = (theme: Theme) => ({
});

interface IProps {
	classes: any;
	t: i18n.TFunction;
}

interface IState {
	open: boolean;
}

class Dashboard extends React.Component<IProps, IState> {
	public state = {
		open: true,
	};

	public render() {
		const {classes, t} = this.props;

		return (
			<Main title={t('title')}>
				{t('dashboardStuff')}
			</Main>
		);
	}

	private handleDrawerOpen = () => {
		this.setState({ open: true });
	}

	private handleDrawerClose = () => {
		this.setState({ open: false });
	}
}

export default withTranslation('dashboard')(withStyles(styles)(Dashboard) as any);

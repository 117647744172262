import {getAPIUrl, getHeaders} from '../global/globals';
import {
	combineCalls,
	createCall,
} from '../global/reduxapi';

export interface IReturn {
	token: string;
	UserId: number;
}

export interface IChangePasswordParameters {
	old_password?: string;
	new_password: string;
	token?: string;
}

export interface IForgotPasswordParameters {
	email: string;
}

export interface ILoginParameters {
	email: string;
	password: string;
}

export interface IRefreshParameters {
	token: string;
}

const {actions, types, reducer} = combineCalls({
	changePassword: createCall<{}, IChangePasswordParameters, {}>({
		headers: getHeaders,
		method: 'post',
		name: 'changePassword',
		url: getAPIUrl('/auth/change_password'),
	}),
	forgotPassword: createCall<{}, IForgotPasswordParameters, {}>({
		headers: getHeaders,
		method: 'post',
		name: 'forgotPassword',
		url: getAPIUrl('/users/forgot_password'),
	}),
	login: createCall<{}, ILoginParameters, {}>({
		headers: getHeaders,
		method: 'post',
		name: 'login',
		url: getAPIUrl('/api-token-auth/'),
	}),
	refresh: createCall<{}, IRefreshParameters, {}>({
		headers: getHeaders,
		method: 'post',
		name: 'refresh',
		url: getAPIUrl('/api-token-refresh/'),
	}),
});
export {actions, types, reducer};

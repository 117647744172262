import i18n from 'i18next';

i18n.init({
	debug: false,
	defaultNS: 'translations',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ',',
	},
	keySeparator: false, // we use content as keys
	ns: ['translations'],
	react: {
		wait: true,
	},
	resources: {
		en: {
			addDevice: {
				qrLocation: 'Code can be found on the underside of the bridge.',
				scanDevice: 'Scan the QR code on the Behaviorcloud Bridge.',
			},
			changePassword: {
				newPassword: 'New Password',
				submit: 'Change Password',
				title: 'Select a new password',
			},
			dashboard: {
				title: 'Dashboard',
			},
			deviceNetworkSelector: {
				networkSelector: 'Choose a Connection Type',
				noConfiguration: 'This device has never been configured. Please select a network configuration below.',
				notRecentlyConfigured: 'This device has been configured but is not currently communicating. Please check connectivity, and if the device’s network has changed, please choose your network configuration below.',
				recentlyConfigured: 'This device was recently configured - please wait for the device to establish connectivity.',
				wired: 'Wired',
				wireless: 'Wireless',
			},
			devices: {
				schema: 'Type',
			},
			forgotPassword: {
				email: 'Email Address',
				sent: 'An email containing instruction for retrieving your password has been sent to the following address: {{email}}',
				submit: 'Send Reset Link',
				title: 'Forgot Password',
			},
			login: {
				'createAccount': 'Create Account',
				'email.label': 'Email Address',
				'email.placeholder': 'christian@example.com',
				'forgotPassword': 'Forgot Password',
				'login': 'Login',
				'logo': 'Behavior Cloud',
				'password.label': 'Password',
				'password.placeholder': 'password',
				'signup': 'Sign up',
				'signupHint': 'Don’t have an account?',
				'title': 'Login',
			},
			manageDevices: {
				addDevice: 'Add Device',
			},
			navigation: {
				account: 'Hi, {{email}}',
				addDevice: 'Add Device',
				dashboard: 'Dashboard',
				deviceNetworkSelector: 'Network Selector',
				deviceSelector: 'deviceSelector',
				devices: 'Devices',
				experiments: 'Experiments',
				help: 'Help',
				login: 'login',
				logout: 'logout',
				manageDevices: 'Manage Device',
				mytests: 'Tests',
				overview: 'Overview',
				public: 'Public Repository',
				stations: 'Stations',
				subjects: 'Subjects',
				wifiSelector: 'Wifi Selector',
			},
			overview: {
				logout: 'Logout',
				manageDatasets: 'Manage Datasets',
				manageDevices: 'Manage Devices',
				welcome: 'Welcome {{email}}',
			},
			tests: {
				owner: 'Owner',
				subjects: 'Subjects',
				title: 'Tests',
				trials: 'Trials',
			},
			wifiSelector: {
				connecting: 'Connecting to BehaviorCloud Bridge: {{ssid}}',
				connectingError: 'Error connecting to {{ssid}}',
				determining: 'Determining current Wifi SSID',
				determiningError: 'There was an error determining current Wifi SSID',
				fetching: 'Fetching list of access points',
				fetchingError: 'There was an error fetching list of access points',
				networkSelector: 'Choose Bridge Network',
				passwordPlaceholder: 'Password',
				passwordTitle: 'Please enter the password for this access point',
				provisioned: 'Successfully provisioned bridge!',
				provisioning: 'Provisioning the bridge with the selected Wifi information.',
				provisioningError: 'There was an error provisioning the bridge.',
				selectWifi: 'Select Wifi Access Point',
				selection: 'Waiting for user to select access point.',
				ssidSelected: 'Using {{wirelessSsid}}',
				status: 'Status: {{status}}',
				waiting: 'Waiting for the Bridge to become available.',
				waitingError: 'Bridge could not be found. There may have been an issue connecting to the Bridge.',
			},
		},
	},
});

export default i18n;

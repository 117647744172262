import {Chip, Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {Theme, withStyles} from '@material-ui/core/styles';
import {Lock, Share} from '@material-ui/icons';
import i18n from 'i18next';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {IStateBucket} from '../../global/reduxapi';
import {actions as deviceActions, IReturn as Device} from '../../resources/device';
import store from '../../store/store';
import DeviceSchemaLabel from '../components/DeviceSchemaLabel';
import Main from '../components/Main';
import UserName from '../components/UserName';

const mapStateToProps = (state: any) => {
	return {
		deviceBucket: state.device,
	};
};

const styles = (theme: Theme) => ({
	root: {
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
		width: '100%',
	},
	table: {
		minWidth: 700,
	},
});

interface IProps {
	classes: any;
	t: i18n.TFunction;
	deviceBucket: IStateBucket<Device>;
}

interface IState {
	open: boolean;
}

let id = 0;
function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
	id += 1;
	return { id, name, calories, fat, carbs, protein };
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
];

class Tests extends React.Component<IProps, IState> {
	public state = {
		open: true,
	};

	public componentDidMount() {
		store.dispatch(deviceActions.index())
			.catch(console.error)
		;
	}

	public render() {
		const {classes, t, deviceBucket} = this.props;
		const devices = Object.values(deviceBucket);

		return (
			<Main title={t('title')}>
				<Paper className={classes.root}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell align='left' />
								<TableCell align='left' />
								<TableCell align='left'>{t('owner')}</TableCell>
								<TableCell align='left'>{t('schema')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{devices.map((device) => (
								<TableRow key={device.id}>
									<TableCell component='th' scope='device'>
										{device.label}
									</TableCell>
									<TableCell align='left'>
										{device.online
											? <Chip label={t('online')} color='primary' />
											: <Chip label={t('offline')} color='secondary' />
										}
									</TableCell>
									<TableCell align='left'><UserName userId={device.user} /></TableCell>
									<TableCell align='left'><DeviceSchemaLabel deviceSchemaId={device.schema} /></TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Paper>
			</Main>
		);
	}
}

export default connect(mapStateToProps)(withTranslation('devices')(withStyles(styles as any)(Tests) as any));

import {combineReducers} from 'redux';
import {reducer as session} from '../global/session';
import {reducer as dataset} from '../resources/dataset';
import {reducer as device} from '../resources/device';
import {reducer as deviceschema} from '../resources/deviceschema';
import {reducer as me} from '../resources/me';
import {reducer as test} from '../resources/test';
import {reducer as user} from '../resources/user';

const state = combineReducers({
	dataset,
	device,
	deviceschema,
	me,
	session,
	test,
	user,
});

export type StateType = ReturnType<typeof state>;
export default state;

import {Theme, Grid, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import i18n from 'i18next';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Session from '../../global/mysession';
import {actions as meActions} from '../../resources/me';
import store from '../../store/store';

const styles = (theme: Theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		margin: theme.spacing.unit,
	},
	form: {
		marginTop: theme.spacing.unit,
		width: '100%', // Fix IE 11 issue.
	},
	logo: {
		display: 'block',
		height: '200px',
		marginBottom: theme.spacing.unit * 3,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: theme.spacing.unit * 8,
	},
	main: {
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			marginLeft: 'auto',
			marginRight: 'auto',
			width: 400,
		},
		width: 'auto',
	},
	paper: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
});

interface IProps {
	classes: any;
	t: i18n.TFunction;
	token?: string;
}

interface IState {
	newPassword: string;
	oldPassword: string;
}

class ChangePassword extends Component<IProps, IState> {
	public state = {
		newPassword: '',
		oldPassword: '',
	};

	public render() {
		const {classes, t, token} = this.props;

		const setNewPassword = (event: React.FormEvent) => this.setState({
			newPassword: (event.target as HTMLInputElement).value,
		});

		const setOldPassword = (event: React.FormEvent) => this.setState({
			newPassword: (event.target as HTMLInputElement).value,
		});

		return (
			<main className={classes.main}>
				<CssBaseline />
				<img src='images/logo.svg' className={classes.logo} alt={t('logo')} />
				<Paper className={classes.paper}>
					<form className={classes.form} onSubmit={this.handleSubmitClick}>
						<Grid container={true} justify='center'>
							<Typography component='h2' variant='h5'>
								{t('title')}
							</Typography>
						</Grid>
						{token &&
							<FormControl margin='normal' required={true} fullWidth={true}>
								<InputLabel htmlFor='oldPassword'>{t('oldPassword')}</InputLabel>
								<Input
									id='oldPassword'
									name='oldPassword'
									autoComplete='password'
									type='password'
									autoFocus={true}
									onChange={setOldPassword}
								/>
							</FormControl>
						}
						<FormControl margin='normal' required={true} fullWidth={true}>
							<InputLabel htmlFor='newPassword'>{t('newPassword')}</InputLabel>
							<Input
								id='newPassword'
								name='newPassword'
								autoComplete='password'
								type='password'
								autoFocus={true}
								onChange={setNewPassword}
							/>
						</FormControl>
						<Button
							type='submit'
							fullWidth={true}
							variant='contained'
							color='primary'
							className={classes.submit}
						>
							{t('submit')}
						</Button>
					</form>
				</Paper>
			</main>
		);
	}

	private handleSubmitClick = (event: React.FormEvent) => {
		event.preventDefault();
		event.stopPropagation();
		const {oldPassword, newPassword} = this.state;
		const {token} = this.props;
		store.dispatch(meActions.changePassword(undefined, {
			new_password: newPassword,
			old_password: oldPassword,
			token,
		}))
			.then(({result}: any) => {
				return true;
			})
			.catch(console.error);
	}
}

export default withTranslation('changePassword')(withStyles(styles as any)(ChangePassword) as any);

import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Lock, Share } from '@material-ui/icons';
import i18n from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IStateBucket } from '../../global/reduxapi';
import { actions as testActions, IReturn as Test } from '../../resources/test';
import store from '../../store/store';
import Main from '../components/Main';
import UserName from '../components/UserName';

const mapStateToProps = (state: any) => {
	return {
		testBucket: state.test,
	};
};

const styles = (theme: Theme) => ({
	root: {
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
		width: '100%',
	},
	table: {
		minWidth: 700,
	},
});

interface IProps {
	classes: any;
	t: i18n.TFunction;
	testBucket: IStateBucket<Test>;
}

interface IState {
	open: boolean;
}

let id = 0;
function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
	id += 1;
	return { id, name, calories, fat, carbs, protein };
}

class Tests extends React.Component<IProps, IState> {
	public state = {
		open: true,
	};

	public componentDidMount() {
		store.dispatch(testActions.index())
			.catch(console.error)
		;
	}

	public render() {
		const {classes, t, testBucket} = this.props;
		const tests = Object.values(testBucket);

		return (
			<Main title={t('title')}>
				<Paper className={classes.root}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell align='left' />
								<TableCell align='left'>{t('owner')}</TableCell>
								<TableCell align='left'>{t('trials')}</TableCell>
								<TableCell align='left'>{t('subjects')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tests.map((test) => (
								<TableRow key={test.id}>
									<TableCell component='th' scope='test'>
										{test.name}
									</TableCell>
									<TableCell align='left'>
										{test.subject_count
											? <Share />
											: <Lock />
										}
									</TableCell>
									<TableCell align='left'><UserName userId={test.user} /></TableCell>
									<TableCell align='left'>0</TableCell>
									<TableCell align='left'>{test.subject_count}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Paper>
			</Main>
		);
	}

	private handleDrawerOpen = () => {
		this.setState({ open: true });
	}

	private handleDrawerClose = () => {
		this.setState({ open: false });
	}
}

export default connect(mapStateToProps)(withTranslation('tests')(withStyles(styles as any)(Tests) as any));

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {IState as Session} from '../../global/session';
import ChangePassword from './ChangePassword';
import Dashboard from './Dashboard';
import Default from './Default';
import Devices from './Devices';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Logout from './Logout';
import Tests from './Tests';

const mapStateToProps = (state: any) => {
	return {
		session: state.session,
	};
};

interface IProps {
	session: Session;
}

class Routes extends Component<IProps> {
	public render() {
		const {session} = this.props;
		const params = new URLSearchParams(document.location.search);

		return (
			<BrowserRouter>
				{session.token === ''
					? <Switch>
						<Route path='/login' component={Login} />
						<Route path='/changepassword' component={ChangePassword} token={params.get('token')} />
						<Route path='/forgotpassword' component={ForgotPassword} />
						<Route component={Default} />
					</Switch>
					: <Switch>
						<Route path='/dashboard' component={Dashboard} />
						<Route path='/devices' component={Devices} />
						<Route path='/logout' component={Logout} />
						<Route path='/mytests' component={Tests} />
						<Route component={Default} />
					</Switch>
				}
			</BrowserRouter>
		);
	}
}

export default connect(mapStateToProps)(Routes);

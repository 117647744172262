import mqtt from 'paho-mqtt';
import store from '../store/store';

const getClientId = function getId(): string {
	let text = '';
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	for (let i = 0; i < 5; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	return text;
};

const ClientId = getClientId();

const NEW_REALTIME_MESSAGE_ARRIVED = 'NEW_REALTIME_MESSAGE_ARRIVED';

const NEW_UPDATE_MESSAGE_ARRIVED = 'NEW_UPDATE_MESSAGE_ARRIVED';

const getRealtimeActionType = function f(resource: string) {
	return NEW_REALTIME_MESSAGE_ARRIVED + '_' + resource;
};

const getUpdateActionType = function f(resource: string) {
	return NEW_UPDATE_MESSAGE_ARRIVED + '_' + resource;
};

type MessageCallback = (message: string) => void;

interface IRealtimeAction {
	type: string;
	id: string;
	message: object;
}

const newMessageArrived = function action(resource: string, id: string, message: object): IRealtimeAction {
	return {
		id,
		message,
		type: getRealtimeActionType(resource),
	};
};

const newUpdateArrived = function action(resource: string, id: string, message: object): IRealtimeAction {
	return {
		id,
		message,
		type: getUpdateActionType(resource),
	};
};

interface IClientList {
	[key: string]: mqtt.Client;
}

interface ICallbackList {
	[key: string]: MessageCallback[];
}

class Realtime {
	private resource: string;
	private id: string;
	private client: mqtt.Client;
	private topic: string;
	private json: boolean;
	private subject: string;

	constructor(
		resource: string,
		id: string,
		url: string,
		topic: string,
		subject: string = 'data',
		json: boolean = true) {

		this.resource = resource;
		this.id = id;
		this.client = new mqtt.Client(url, `${ClientId}-${id}-${subject}`);
		this.topic = topic;
		this.json = json;
		this.subject = subject;
		this.connect();
		this.client.onMessageArrived = this.onMessageArrived;
		this.client.onConnectionLost = this.onConnectionLost;
		(this.client as any).disconnectedPublishing = true;
		(this.client as any).disconnectedBufferSize = 100000;
	}

	public connect = () => {
		const connectOptions = {
			onFailure: this.onFailure,
			onSuccess: this.onSuccess,
			reconnect: true,
		};
		this.client.connect(connectOptions);
	}

	public publish = (payload: string | object) => {
		if (typeof payload === 'object') {
			payload = JSON.stringify(payload);
		}
		const message = new mqtt.Message(payload);
		message.destinationName = this.topic;
		this.client.send(message);
	}

	private onSuccess = () => {
		console.log('subscribing to topic', this.topic);
		this.client.subscribe(this.topic);
	}

	private onFailure() {
		console.log('failure', arguments);
	}

	private onMessageArrived = (message: mqtt.Message) => {
		const payload = message.payloadString;
		if (this.subject === 'data') {
			store.dispatch(newMessageArrived(this.resource, this.id, this.json ? JSON.parse(payload) : payload));
		}
		if (this.subject === 'updates') {
			store.dispatch(newUpdateArrived(this.resource, this.id, this.json ? JSON.parse(payload) : payload));
		}
	}

	private onConnectionLost = (error: mqtt.MQTTError) => {
		console.log('onConnectionLost', error);
	}
}

type Reducer<ST, AT> = (state?: ST, action?: AT) => ST;

function wrapReducer<ST, AT>(resource: string, reducer: Reducer<ST, AT>): Reducer<ST, AT> {
	const wrap: Reducer<ST, AT> = (st?: ST, act?: AT) => {
		let state = reducer(st, act) as any;
		const action = act as unknown as IRealtimeAction;
		let entity = null;
		switch (action.type) {
			case getRealtimeActionType(resource):
				state = {...state};
				entity = state[action.id] = {...state[action.id]};
				entity.messages = [...(entity.messages || [])];
				entity.messages.push(action.message);
				break;
			case getUpdateActionType(resource):
				state = {...state};
				console.log('action update::', action.type, action.message);
				entity = state[action.id] = {...state[action.id], ...action.message};
				break;
		}
		return state;
	};
	return wrap;
}

export {
	Realtime,
	wrapReducer,
};

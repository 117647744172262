import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Session from '../../global/mysession';

class Logout extends Component {
	public componentDidMount() {
		Session.setToken('');
	}

	public render() {
		return <React.Fragment />;
	}
}

export default withTranslation('logout')(Logout);

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { Theme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import React from 'react';
import Navigation from './Navigation';

const drawerWidth = 240;

const styles = (theme: Theme) => ({
	appBar: {
		transition: theme.transitions.create(['width', 'margin'], {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp,
		}),
		zIndex: theme.zIndex.drawer + 1,
	},
	appBarShift: {
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['width', 'margin'], {
			duration: theme.transitions.duration.enteringScreen,
			easing: theme.transitions.easing.sharp,
		}),
		width: `calc(100% - ${drawerWidth}px)`,
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
		padding: theme.spacing.unit * 3,
	},
	drawerPaper: {
		position: 'relative',
		transition: theme.transitions.create('width', {
			duration: theme.transitions.duration.enteringScreen,
			easing: theme.transitions.easing.sharp,
		}),
		whiteSpace: 'nowrap',
		width: drawerWidth,
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp,
		}),
		width: theme.spacing.unit * 7,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing.unit * 9,
		},
	},
	h5: {
		marginBottom: theme.spacing.unit * 2,
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	root: {
		display: 'flex',
	},
	title: {
		flexGrow: 1,
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
});

interface IProps {
	classes: any;
	title: string;
}

interface IState {
	open: boolean;
}

class Main extends React.Component<IProps, IState> {
	public state = {
		open: true,
	};

	public render() {
		const {children, classes, title} = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar
					position='absolute'
					className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
				>
					<Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
						<IconButton
							color='inherit'
							aria-label='Open drawer'
							onClick={this.handleDrawerOpen}
							className={classNames(
								classes.menuButton,
								this.state.open && classes.menuButtonHidden,
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component='h1'
							variant='h6'
							color='inherit'
							noWrap={true}
							className={classes.title}
						>
							{title}
						</Typography>
					</Toolbar>
				</AppBar>
				<Drawer
					variant='permanent'
					classes={{
						paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
					}}
					open={this.state.open}
				>
					<div className={classes.toolbarIcon}>
						<IconButton onClick={this.handleDrawerClose}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<Navigation />
				</Drawer>
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					{children}
				</main>
			</div>
		);
	}

	private handleDrawerOpen = () => {
		this.setState({ open: true });
	}

	private handleDrawerClose = () => {
		this.setState({ open: false });
	}
}

export default withStyles(styles as any)(Main);

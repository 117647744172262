let Config: any = {};

const getConfig = function get(): any {
	return Config;
};

const setConfig = function set(config: any) {
	Config = config;
};

export {
	getConfig,
	setConfig,
};

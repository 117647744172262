import {getConfig} from './config';
import {getQueryString} from './reduxapi';

type IParameterBag = object;
type Id = string | number;

const getAPIUrl = function get(relativeUrl: string) {
	relativeUrl = '/api/1.1' + relativeUrl;
	if (getConfig().API_URL) {
		return getConfig().API_URL + relativeUrl;
	}
	return relativeUrl;
};

const getCallAPIUrlFunction = function get<T extends IParameterBag>(relativeUrl: string, call: string) {
	return function urlF(id?: Id, urlParameters?: T) {
		const rootUrl = getAPIUrl(relativeUrl);
		return rootUrl
			+ '/'
			+ id
			+ '/'
			+ call
			+ getQueryString<T>(urlParameters)
		;
	};
};

const UuidPattern = /([0-9a-f]{8})-?([0-9a-f]{4})-?([0-9a-f]{4})-?([0-9a-f]{4})-?([0-9a-f]{12})/;
const UuidReplace = '$1-$2-$3-$4-$5';
const formatUuid = function format(uuid: string) {
	return uuid.replace(UuidPattern, UuidReplace);
};

const getHeaders = function get() {
	const mySession = require('./mysession').default;
	return {
		'Accept': 'application/json',
		'Authorization': 'JWT ' + mySession.getToken(),
		'Content-Type': 'application/json',
	};
};

const transformIndex = function transformIndexF(payload: any) {
	return payload;
};

const ping = function onPing(host: string): Promise<boolean> {
	return new Promise<boolean>((accept: (result: boolean) => void) => {
		fetch(host)
			.then((response) => {
				console.log('response', response);
				accept(true);
			})
			.catch((error) => {
				console.log('catch', error);
				accept(false);
			});
	});
};

const waitFor = function onWaitFor(condition: () => Promise<boolean>, waitTimeout = 1000, failTimeout?: number):
	Promise<void> {
	return new Promise<void>((accept: () => void, reject: () => void) => {
		let failInterval: number;
		let checkInterval: number;
		let resolved = false;
		const clearIntervals = function onClearIntervals() {
			if (failInterval) {
				clearInterval(failInterval);
			}
			if (checkInterval) {
				clearInterval(checkInterval);
			}
		};
		const fail = function onFail() {
			clearIntervals();
			if (!resolved) {
				reject();
			}
			resolved = true;
		};
		const succeed = function onSucceed() {
			clearIntervals();
			if (!resolved) {
				accept();
			}
			resolved = true;
		};
		const check = function onCheck() {
			checkInterval = setTimeout(check, waitTimeout);
			condition()
				.then((result) => {
					if (!result) {
						return;
					}
					succeed();
				})
				.catch(fail);
		};
		check();
		if (failTimeout) {
			failInterval = setTimeout(fail, failTimeout);
		}
	});
};

export {
	formatUuid,
	getAPIUrl,
	getCallAPIUrlFunction,
	getHeaders,
	ping,
	transformIndex,
	waitFor,
};

interface IFetchResponse<T> {
	status: number;
	json: () => Promise<T>;
}

export interface IActionResult<S, T, U> {
	data?: T;
	id?: Id;
	response?: IFetchResponse<U>;
	result?: U;
	type?: string;
	urlParameters?: S;
}

type Dispatch<R> = (arg: R) => R;

type PromiseResult<S, T, U> = Promise<IActionResult<S, T, U>> & {
	type: string;
};

type Id = string | number;

interface ICacheOptions<T> {
	store: {getState: () => any};
	invalid?: (entity: IHasMeta<T>) => boolean;
}

type CacheReadAction<S, T, U> = (cacheOptions: ICacheOptions<U>, id: Id, data?: T, urlParameters?: S) => any;
type RunAction<S, T, U> = (id?: Id, data?: T, urlParameters?: S) => any;
type InitiateAction<S, T, U> = (id?: Id, data?: T, urlParameters?: S) => IActionResult<S, T, U>;
type InvalidateAction<S, T, U> = (id: Id, data?: T, urlParameters?: S) => {id?: Id, type: string};
type SuccessAction<S, T, U> = (response: IFetchResponse<U>, result: U, id?: Id, data?: T, urlParameters?: S) => IActionResult<S, T, U>;
type FailureAction<S, T, U> = (response: IFetchResponse<U>, result: U, id?: Id, data?: T, urlParameters?: S) => IActionResult<S, T, U>;

export interface IMeta {
	loadFailed: Date | null;
	loaded: Date | null;
	loading: Date | null;
}

type IGenericActionResult = any;

interface IGenericActionBag {
	[key: string]: (...args: any[]) => IGenericActionResult;
}

type GenericReducer<T> = (state: T, action: IGenericActionResult) => T;

interface IGenericCall<T> {
	actions: IGenericActionBag;
	reducer: GenericReducer<T>;
	types: ITypeBag;
}

interface IGenericCallBag<T> {
	[key: string]: IGenericCall<T>;
}

interface ICallActionBag<S, T, U> extends IGenericActionBag {
	run: RunAction<S, T, U>;
	initiate: InitiateAction<S, T, U>;
	success: SuccessAction<S, T, U>;
	failure: FailureAction<S, T, U>;
}

interface IResourceActionBag<S, T, U extends object> {
	index: RunAction<S, T, U>;
	indexInitiate: InitiateAction<S, T, U>;
	indexSuccess: SuccessAction<S, T, U>;
	indexFailure: FailureAction<S, T, U>;
	create: RunAction<S, T, U>;
	createInitiate: InitiateAction<S, T, U>;
	createSuccess: SuccessAction<S, T, U>;
	createFailure: FailureAction<S, T, U>;
	cacheread: CacheReadAction<S, T, U>;
	invalidate: InvalidateAction<S, T, U>;
	read: RunAction<S, T, U>;
	readInitiate: InitiateAction<S, T, U>;
	readSuccess: SuccessAction<S, T, U>;
	readFailure: FailureAction<S, T, U>;
	update: RunAction<S, T, U>;
	updateInitiate: InitiateAction<S, T, U>;
	updateSuccess: SuccessAction<S, T, U>;
	updateFailure: FailureAction<S, T, U>;
	delete: RunAction<S, T, U>;
	deleteInitiate: InitiateAction<S, T, U>;
	deleteSuccess: SuccessAction<S, T, U>;
	deleteFailure: FailureAction<S, T, U>;
}

interface ITypeBag {
	[key: string]: string;
}

type IParameterBag = object;

type IHasMeta<T> = T & {
	_meta: IMeta;
};

export interface IStateBucket<T extends object> {
	[key: string]: IHasMeta<T>;
}

export type FlatReducer<S, T, U extends object> = (state: U | undefined, action: IActionResult<S, T, U>) => U;
export type BucketReducer<S, T, U extends object> = (state: IStateBucket<U> | undefined, action: IActionResult<S, T, U>) => IStateBucket<U>;

interface ICall<S, T, U extends object> {
	actions: ICallActionBag<S, T, U>;
	reducer: FlatReducer<S, T, U>;
	types: ITypeBag;
	name: string;
}

interface IResource<S, T, U extends object> {
	actions: IResourceActionBag<S, T, U>;
	reducer: BucketReducer<S, T, U>;
	types: ITypeBag;
	name: string;
	updateEntity: (state: IStateBucket<U>, id: Id, newData: U) => IStateBucket<U>;
}

interface ICallBag<S, T, U extends object> {
	[key: string]: ICall<S, T, U>;
}

interface ICallOptions<S, T, U extends object> {
	headers?: object;
	idField?: string;
	method?: string;
	name: string;
	parseResult?: (resultBody: any) => U;
	preFetch?: () => void;
	reducer?: FlatReducer<S, T, U>;
	stringifyBody?: (requestBody: T) => string;
	successCodes?: number[];
	typePrefix?: string;
	url: ((id?: Id, urlParameters?: S) => string) | string;
}

interface IResourceOptions<S, T, U> {
	headers?: object;
	idField?: string;
	methodCreate?: string;
	methodDelete?: string;
	methodIndex?: string;
	methodRead?: string;
	methodUpdate?: string;
	name: string;
	parseResult?: (resultBody: any) => U;
	preFetch?: () => void;
	rootUrl: string;
	stringifyBody: (requestBody: T) => string;
	successCodes?: number[];
	transformIndex: (result: any) => U[];
	updateStateOnUpdateInitiate?: boolean;
	updateStateOnUpdateSuccess?: boolean;
}

const combineTypes = function combineTypesF<S, T, U extends object>(calls: ICallBag<S, T, U>) {
	let types = {};
	Object.values(calls).forEach((call: ICall<S, T, U>) => (types = {...types, ...call.types}));
	return types;
};

const combineReducers = function combineReducersF<S, T, U extends object>(resource: IResource<S, T, U> | null, calls: IGenericCallBag<IStateBucket<U>>) {
	return function combinedReducer(state: IStateBucket<U> = {}, action: IGenericActionResult) {
		state = state || DefaultBucketReducer;
		if (resource) {
			state = resource.reducer(state, action);
		}
		Object.values(calls).forEach(function each(call) {
			if (call.reducer) {
				state = call.reducer(state, action);
			}
		});
		return state;
	};
};

const combineCallReducers = function combineCallReducersF<U extends object>(calls: IGenericCallBag<U>) {
	return function combinedReducer(state = {} as U, action: IGenericActionResult): U {
		Object.values(calls).forEach(function each(call) {
			if (call.reducer) {
				state = call.reducer(state, action);
			}
		});
		return state;
	};
};

const DefaultFlatReducer = function DefaultFlatReducerF<S, T, U extends object>(state: U = {} as U, action: IGenericActionResult): U {
	return state;
};

const DefaultBucketReducer = function DefaultBucketReducerF<S, T, U extends object>(state: IStateBucket<U>, action: IGenericActionResult): IStateBucket<U> {
	return state;
};

const combine = function combineF<S, T, U extends object, C extends IGenericCallBag<IStateBucket<U>>>(resource: IResource<S, T, U>, calls: C = {} as C) {
	let types: ITypeBag = {};
	types = {...types, ...resource.types};
	Object.values(calls).forEach((call: IGenericCall<IStateBucket<U>>) => (types = {...types, ...call.types}));

	const reducer = combineReducers(resource, calls);

	type ActionsType = {
		[P in keyof C]: C[P]['actions']['run'];
	};

	type FullActions = ActionsType & IResourceActionBag<S, T, U>;

	const actions: FullActions = resource.actions as FullActions;
	Object.keys(calls).forEach(function each(key) {
		actions[key] = calls[key].actions.run;
	});

	return {
		actions,
		reducer,
		types,
	};
};

const combineCalls = function combineCallsF<U extends object, C extends IGenericCallBag<U>>(calls: C = {} as C) {
	let types: ITypeBag = {};
	Object.values(calls).forEach((call: IGenericCall<U>) => (types = {...types, ...call.types}));

	const reducer = combineCallReducers(calls);

	type ActionsType = {
		[P in keyof C]: C[P]['actions']['run'];
	};

	const actions = {} as ActionsType;
	Object.keys(calls).forEach(function each(key) {
		actions[key] = calls[key].actions.run;
	});

	return {
		actions,
		reducer,
		types,
	};
};

const getQueryString = function getQueryStringF<T extends IParameterBag>(urlParameters?: T) {
	if (!urlParameters) {
		return '';
	}
	const parameters = urlParameters as {[key: string]: string};
	return '?' + Object
		.keys(parameters)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]))
		.join('&');
};

const idUrlBuilder = function idUrlBuilderF<T extends IParameterBag>(rootUrl: string) {
	return function urlF(id?: Id, urlParameters?: T) {
		rootUrl = rootUrl.replace(/\/$/, '');
		return rootUrl
			+ '/'
			+ id
			+ getQueryString<T>(urlParameters)
		;
	};
};

const urlBuilder = function urlBuilderF<T extends IParameterBag>(url: string) {
	return function urlF(id?: Id, urlParameters?: T) {
		url = url.replace(/\/$/, '');
		return url + getQueryString<T>(urlParameters);
	};
};

const createCall = function createCallF<S, T, U extends object>(newOptions: ICallOptions<S, T, U>): ICall<S, T, U> {
	const defaultCallOptions = {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		idField: 'id',
		method: 'get',
		parseResult: (resultBody: any) => resultBody as U,
		preFetch: () => null,
		stringifyBody: (requestBody: U) => JSON.stringify(requestBody),
		successCodes: [200, 201],
		typePrefix: '',
	};

	const options = {...defaultCallOptions, ...newOptions};
	const name = options.name;
	const stringifyBody = options.stringifyBody as (requestBody: T) => string;
	const runType = options.typePrefix + options.name.toUpperCase();
	const initiateType = options.typePrefix + options.name.toUpperCase() + '_INITIATE';
	const successType = options.typePrefix + options.name.toUpperCase() + '_SUCCESS';
	const failureType = options.typePrefix + options.name.toUpperCase() + '_FAILURE';
	const types: ITypeBag = {};
	[runType, initiateType, successType, failureType].forEach((type) => (types[type] = type));

	const initiate: InitiateAction<S, T, U> = (id?: Id, data?: T, urlParameters?: S) => {
		return {
			data,
			id,
			type: initiateType,
			urlParameters,
		};
	};
	const success: SuccessAction<S, T, U> = (response, result: U, id?: Id, data?: T, urlParameters?: S) => {
		return {
			data,
			id,
			response,
			result,
			type: successType,
			urlParameters,
		};
	};
	const failure: FailureAction<S, T, U> = (response, result: U, id?: Id, data?: T, urlParameters?: S) => {
		return {
			data,
			id,
			response,
			result,
			type: failureType,
			urlParameters,
		};
	};

	const run: RunAction<S, T, U> = function runF(id?: Id, data?: T, urlParameters?: S): any {
		return function returnF(dispatch: Dispatch<IActionResult<S, T, U>>): PromiseResult<S, T, U> {
			dispatch(initiate(id, data, urlParameters));
			const headers = typeof options.headers === 'function' ? options.headers() : options.headers;
			const url = typeof options.url === 'function' ? options.url(id, urlParameters) : options.url;
			let fetchResponse: IFetchResponse<U>;
			let fetchResult: U;
			const fetchOptions = {
				body: '',
				headers,
				method: options.method,
			};
			if (data && stringifyBody(data)) {
				fetchOptions.body = stringifyBody(data);
			}
			options.preFetch();
			if (!fetchOptions.body) {
				delete fetchOptions.body;
			}
			const fetchReturn = fetch(url, fetchOptions)
				.then(function then(response: IFetchResponse<U>) {
					fetchResponse = response;
					return response.json();
				})
				.then(function then(result) {
					return options.parseResult(result);
				});
			return (fetchReturn as any)
				.then(function then(result: U) {
					fetchResult = result;
					if (options.successCodes.indexOf(fetchResponse.status) === -1) {
						dispatch(failure(fetchResponse, fetchResult, id, data, urlParameters));
						const actionResult: IActionResult<S, T, U> = {
							response: fetchResponse,
							result: fetchResult,
						};

						return Promise.reject(actionResult);
					}
					return dispatch(success(fetchResponse, fetchResult, id, data, urlParameters));
				})
				.catch((error: any) => {
					dispatch(failure(fetchResponse, fetchResult, id, data, urlParameters));
					return Promise.reject({
						error,
						response: fetchResponse,
						result: fetchResult,
					});
				});
		};
	};

	const actions = {
		failure,
		initiate,
		run,
		success,
	};
	const reducer = options.reducer || DefaultFlatReducer;

	return {actions, types, reducer, name};
};

const defaultResourceOptions = {
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	},
	idField: 'id',
	methodCreate: 'post',
	methodDelete: 'delete',
	methodIndex: 'get',
	methodRead: 'get',
	methodUpdate: 'put',
	preFetch: () => null,
	successCodes: [200, 201],
	transformIndex: (result: any) => result,
	updateStateOnUpdateInitiate: true,
	updateStateOnUpdateSuccess: true,
};

const createResource = function createResourceF<S extends IParameterBag, T, U extends object>(options: IResourceOptions<S, T, U>): IResource<S, T, U> {
	options = {...defaultResourceOptions, ...options};
	const idField = options.idField as string;
	const name = options.name;

	const calls = {
		create: createCall<S, T, U>({...options, method: options.methodCreate, name: 'create',
			typePrefix: options.name.toUpperCase() + '_', url: urlBuilder(options.rootUrl)}),
		delete: createCall<S, T, U>({...options, method: options.methodDelete, name: 'delete',
			typePrefix: options.name.toUpperCase() + '_', url: idUrlBuilder(options.rootUrl)}),
		index: createCall<S, T, U>({...options, method: options.methodIndex, name: 'index', parseResult: (a: any) => a,
			typePrefix: options.name.toUpperCase() + '_', url: urlBuilder(options.rootUrl)}),
		read: createCall<S, T, U>({...options, method: options.methodRead, name: 'read',
			typePrefix: options.name.toUpperCase() + '_', url: idUrlBuilder(options.rootUrl)}),
		update: createCall<S, T, U>({...options, method: options.methodUpdate, name: 'update',
			typePrefix: options.name.toUpperCase() + '_', url: idUrlBuilder(options.rootUrl)}),
	};

	const types = combineTypes(calls);

	const getEntity = (cacheOptions: ICacheOptions<U>, id: Id): IHasMeta<U> | null => {
		const {store} = cacheOptions;
		const state = store.getState();
		if (!state.hasOwnProperty(options.name) || !state[options.name].hasOwnProperty(id)) {
			return null;
		}
		return state[options.name][id];
	};

	const isValid = function isValidF(cacheOptions: ICacheOptions<U>, id: Id) {
		const entity = getEntity(cacheOptions, id);

		// If the id does not exist in our store, it is not valid
		if (!entity) {
			return false;
		}

		// If the entity does not pass the custom "invalid" check, it is not valid
		if (cacheOptions.invalid && cacheOptions.invalid(entity)) {
			return false;
		}

		// If the entity failed loading, it is not valid
		const meta = entity._meta || {};
		if (meta.loadFailed) {
			return false;
		}

		return true;
	};

	const cacheread: CacheReadAction<S, T, U> = function cachereadF(cacheOptions: ICacheOptions<U>, id: Id, data?: T, urlParameters?: S): any {
		return function crReturn(dispatch: Dispatch<IActionResult<S, T, U>>): PromiseResult<S, T, U> {
			if (isValid(cacheOptions, id)) {
				const result = getEntity(cacheOptions, id) as U;
				return Promise.resolve({
					result,
				}) as PromiseResult<S, T, U>;
			}
			const readResult = actions.read(id, data, urlParameters);
			return readResult(dispatch);
		};
	};

	const invalidate = function invalidateF(id: Id, data?: T, urlParameters?: S) {
		return {
			data,
			id,
			type: options.name.toUpperCase() + '_INVALIDATE',
			urlParameters,
		};
	};

	const actions: IResourceActionBag<S, T, U> = {
		cacheread,
		create: calls.create.actions.run,
		createFailure: calls.create.actions.failure,
		createInitiate: calls.create.actions.initiate,
		createSuccess: calls.create.actions.success,
		delete: calls.delete.actions.run,
		deleteFailure: calls.delete.actions.failure,
		deleteInitiate: calls.delete.actions.initiate,
		deleteSuccess: calls.delete.actions.success,
		index: calls.index.actions.run,
		indexFailure: calls.index.actions.failure,
		indexInitiate: calls.index.actions.initiate,
		indexSuccess: calls.index.actions.success,
		invalidate,
		read: calls.read.actions.run,
		readFailure: calls.read.actions.failure,
		readInitiate: calls.read.actions.initiate,
		readSuccess: calls.read.actions.success,
		update: calls.update.actions.run,
		updateFailure: calls.update.actions.failure,
		updateInitiate: calls.update.actions.initiate,
		updateSuccess: calls.update.actions.success,
	};

	const updateEntity = function updateEntityF(state: IStateBucket<U>, id: Id, newData: U): IStateBucket<U> {
		const entity = state[id] || {};
		const meta = entity._meta || {};
		state = {...state};
		state[id] = {
			...entity,
			...newData,
		};
		state[id]._meta = {
			...meta,
			loadFailed: null,
			loaded: new Date(),
			loading: null,
		};
		return state;
	};

	const reducer = function reducerF(state: IStateBucket<U> = {}, action: IActionResult<S, T, U>): IStateBucket<U> {
		if (action.type === (name.toUpperCase() + '_READ_INITIATE')) {
			const id = action.id as Id;
			const entity = state[id] || {} as U;
			const meta = entity._meta || {};
			state = {...state};
			state[id] = {
				...entity,
			};
			state[id]._meta = {
				...meta,
				loadFailed: null,
				loaded: null,
				loading: new Date(),
			};
			return state;
		} else if (action.type === (name.toUpperCase() + '_READ_FAILURE')) {
			const id = action.id as Id;
			const entity = state[id] || {};
			const meta = entity._meta || {};
			state = {...state};
			state[id] = {
				...entity,
			};
			state[id]._meta = {
				...meta,
				loadFailed: new Date(),
				loaded: null,
				loading: null,
			};
			return state;
		} else if (action.type === (name.toUpperCase() + '_READ_SUCCESS')) {
			const result = action.result as U;
			const id = action.id as Id;
			return updateEntity(state, id, result);
		} else if (action.type === (name.toUpperCase() + '_UPDATE_INITIATE')) {
			const id = action.id as Id;
			if (!options.updateStateOnUpdateInitiate) {
				return state;
			}
			return updateEntity(state, id, action.data as unknown as U);
		} else if (action.type === (name.toUpperCase() + '_UPDATE_SUCCESS')) {
			if (!options.updateStateOnUpdateSuccess) {
				return state;
			}
			const id = action.id as Id;
			const result = action.result as U;
			return updateEntity(state, id, result);
		} else if (action.type === (name.toUpperCase() + '_CREATE_SUCCESS')) {
			const result = action.result as U;
			const id = (result as any)[idField] as Id;
			return updateEntity(state, id, result);
		} else if (action.type === (name.toUpperCase() + '_INDEX_SUCCESS')) {
			state = {...state};
			const results = options.transformIndex(action.result);
			results.forEach((item: U) => {
				const id = (item as any)[idField] as Id;
				const entity = state[id] || {};
				state[id] = {
					...entity,
					...item,
				};
				const meta = state[id]._meta || {};
				state[id]._meta = {
					...meta,
					loadFailed: null,
					loaded: new Date(),
					loading: null,
				};
			});
			return state;
		} else if (action.type === (name.toUpperCase() + '_INVALIDATE')) {
			state = {...state};
			const id = action.id as Id;
			const entity = state[id] || {};
			const meta = entity._meta || {};
			state[id] = {
				_meta: {
					...meta,
					loadFailed: null,
					loaded: null,
					loading: null,
				},
			} as IHasMeta<U>;
			return state;
		}
		return state;
	};

	return {actions, name, types, reducer, updateEntity};
};

export {
	createCall,
	createResource,
	combine,
	combineCalls,
	getQueryString,
	idUrlBuilder,
};

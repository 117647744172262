import {applyMiddleware, compose, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['session'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};
const enhancers = [];
const middleware = [
	thunk,
];

if (true || (typeof(__DEV__) !== 'undefined' && __DEV__)) {
	const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}
}

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers,
);

const store = createStore(
	persistedReducer,
	initialState,
	composedEnhancers,
);

export const persistor = persistStore(store);
export default store;

import React from 'react';
import {render} from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import i18n from '../global/i18n';
import store, {persistor} from '../store/store';
import Routes from './containers/Routes';

const rootElement = document.getElementById('root');

persistor.subscribe(() => {
	render(
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>
				<Routes />
			</Provider>
		</I18nextProvider>
	, rootElement);
});
